import { GeneralTokenDetailsResponse } from "api";
import {
    CustomerAgreement,
    CustomerItem,
    CustomerTransactionDetail,
} from "api/types/customer";
import { formatUnits } from "ethers";
import { ReactNode } from "react";
import { ItemCategoryType, TransferStatus } from "types/common-enums";
import {
    describeFrequency,
    getDateInSeconds,
    getReadableDate,
} from "utils/dates";
import { toDollar, toCoin } from "utils/financial";
import { isNullish } from "utils/numbers";
import { isTransactionUpcomingOrDue } from "utils/transactions";

export const getSubscriptionAmount = (
    items: Pick<CustomerItem, "amount" | "type">[]
) => {
    // The "amount" is the sum of subscription items, `null` if any of those is priced in token
    return items.reduce<bigint | null>((sum, { amount, type }) => {
        if (type !== ItemCategoryType.Subscription) return sum;
        if (sum === null || amount === undefined) return null;

        return sum + BigInt(amount);
    }, BigInt(0));
};

export const getSubscriptionNextPayment = (
    nextScheduledTx:
        | Pick<
              CustomerTransactionDetail,
              "amount" | "status" | "usd" | "billDate"
          >
        | undefined,
    token: Pick<GeneralTokenDetailsResponse, "decimals" | "symbol">,
    isLastPayment: boolean = false
) => {
    const isPastDue =
        (nextScheduledTx && nextScheduledTx.billDate < getDateInSeconds()) ||
        false;

    return nextScheduledTx
        ? `Your ${isLastPayment ? `last` : isPastDue ? `late` : `next`} payment${
              Number(nextScheduledTx.amount) === 0 &&
              nextScheduledTx.status === TransferStatus.Draft
                  ? ``
                  : ` of ${
                        nextScheduledTx.usd
                            ? toDollar(nextScheduledTx.amount)
                            : `${toCoin(
                                  Number(
                                      formatUnits(
                                          nextScheduledTx.amount,
                                          token?.decimals ?? 18
                                      )
                                  )
                              )} ${token.symbol}`
                    }`
          } ${isPastDue ? `was` : `is`} due on ${getReadableDate(nextScheduledTx.billDate)}`
        : `No payments are scheduled`;
};

export const getSubscriptionCancelMessage = (
    agreement: Pick<CustomerAgreement, "endDate" | "cancellationEffectiveDate">
) => {
    return agreement.endDate
        ? `Canceled on ${getReadableDate(agreement.endDate)}`
        : agreement.cancellationEffectiveDate
          ? `Scheduled for cancelation on ${getReadableDate(
                agreement.cancellationEffectiveDate
            )}`
          : ``;
};

export const getStatusOfNextPayment = (
    agreement: Pick<CustomerAgreement, "endDate" | "cancellationEffectiveDate">,
    nextScheduledTx:
        | Pick<
              CustomerTransactionDetail,
              "amount" | "status" | "usd" | "billDate"
          >
        | undefined,
    token: Pick<GeneralTokenDetailsResponse, "decimals" | "symbol">
) => {
    return (agreement.cancellationEffectiveDate &&
        nextScheduledTx?.status !== TransferStatus.Draft &&
        isTransactionUpcomingOrDue(nextScheduledTx?.status)) ||
        (!agreement.endDate && !agreement.cancellationEffectiveDate)
        ? getSubscriptionNextPayment(
              nextScheduledTx,
              token,
              !!agreement.cancellationEffectiveDate
          )
        : getSubscriptionCancelMessage(agreement);
};

export const hasSufficientAmountForNextPayment = ({
    nextPayment,
    amountUsd,
    amountCoin,
}: {
    nextPayment: Pick<CustomerTransactionDetail, "amount" | "usd"> | undefined;
    amountUsd: string | null;
    amountCoin: string | null;
}) => {
    if (!nextPayment) return false;
    return nextPayment.usd
        ? !isNullish(amountUsd) &&
              BigInt(amountUsd) >= BigInt(nextPayment.amount)
        : !isNullish(amountCoin) &&
              BigInt(amountCoin) >= BigInt(nextPayment.amount);
};

export const getSubscriptionFrequency = (
    items: Pick<CustomerItem, "frequency" | "type">[]
): string => {
    const subscriptions = items.filter(
        (item) => item.frequency && item.type === ItemCategoryType.Subscription
    );

    if (!subscriptions.length) return "";

    const [first] = subscriptions;
    if (subscriptions.length === 1) return describeFrequency(first.frequency!);

    const hasMatchingFrequencies = subscriptions.every(
        (sub) =>
            sub.frequency?.type === first.frequency?.type &&
            sub.frequency?.value === first.frequency?.value
    );

    return hasMatchingFrequencies
        ? describeFrequency(first.frequency!)
        : "across multiple payment intervals";
};

export const getSubscriptionAmountAndFrequency = (
    amount: bigint | null,
    frequency: string
): ReactNode => {
    return amount === null
        ? `Priced in token`
        : amount <= BigInt(0)
          ? `Price varies`
          : `${toDollar(amount)} ${frequency}`;
};
