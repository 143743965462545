import { useState, useEffect } from "react";
import { CheckoutItemResponse } from "api/types/checkout";
import { CheckoutItem } from "checkout/types";
import { ExchangableToken } from "types/common";
import { getDateInSeconds, TimeFrequency } from "utils/dates";
import { strFrequency } from "utils/datetime";
import { addTimePeriodToDate } from "utils/dates";
import {
    formatUnitsWithPrecision,
    toCoin,
    toDollar,
    undecimalizeNumber,
} from "utils/financial";
import { toNumber } from "utils/numbers";
import { CouponDetails } from "checkout/context/CheckoutData/useCouponsWithDetails";
import { calculateAmountAfterDiscount } from "utils/checkout";
import { convertCentsToToken } from "utils/exchangeRates";

export const useCreateCheckoutItems = (
    items: CheckoutItemResponse[] | undefined,
    couponsWithDetails: CouponDetails[],
    tokens: ExchangableToken[] | undefined,
    billDate?: number
) => {
    const [checkoutItems, setCheckoutItems] = useState<CheckoutItem[]>([]);

    useEffect(() => {
        if (!tokens) {
            setCheckoutItems([]);
            return;
        }

        // Standardize the time we apply offset days from
        const currentDate = getDateInSeconds();

        setCheckoutItems(
            items?.map((item) => {
                const itemAmount =
                    item.amount === null ? null : toNumber(item.amount);

                // Fixed logic
                const isVariablePricing = itemAmount === 0;
                const isOneTimePayment = item.frequency.value === 0;
                const hasInitialOffset = item.initialOffset > 0;

                // Has coupon
                const couponDetail = couponsWithDetails.find((couponDetail) =>
                    couponDetail.itemIds.includes(item.id)
                );

                const hasCoupon = !!couponDetail;

                const initialOffsetForDisplay = `${item.initialOffset} day${
                    item.initialOffset !== 1 ? "s" : ""
                } free`;

                const frequencyForDisplay = strFrequency(
                    item.frequency.value,
                    item.frequency.type
                );

                const amountForDisplay = isVariablePricing
                    ? "Price varies"
                    : itemAmount !== null
                      ? toDollar(itemAmount)
                      : ``;

                let dueDate: number = 0;

                if (billDate) dueDate = billDate * 1000;
                else if (item.initialOffset)
                    dueDate = addTimePeriodToDate({
                        from: currentDate,
                        addend: item.initialOffset,
                        type: TimeFrequency.DAY,
                    });

                const amountAfterDiscount =
                    hasCoupon && !isVariablePricing && itemAmount !== null
                        ? calculateAmountAfterDiscount(itemAmount, {
                              ...couponDetail,
                          })
                        : itemAmount;

                const amountAfterDiscountForDisplay =
                    amountAfterDiscount === null
                        ? ""
                        : toDollar(amountAfterDiscount);

                // Append token symbol to prices
                const itemPrices =
                    item.prices?.map((price) => {
                        const token = tokens.find(
                            (token) =>
                                token.address === price.tokenAddress &&
                                token.networkId === price.network
                        );

                        const amountAfterDiscount =
                            hasCoupon && !isVariablePricing && price.amount
                                ? String(
                                      calculateAmountAfterDiscount(
                                          price.amount,
                                          {
                                              ...couponDetail,
                                              discountAmount: toNumber(
                                                  undecimalizeNumber(
                                                      convertCentsToToken(
                                                          couponDetail.discountAmount ??
                                                              0,
                                                          token?.exchange
                                                              .rate ?? 1
                                                      ),
                                                      token?.decimals
                                                  )
                                              ),
                                          }
                                      )
                                  )
                                : price.amount;

                        return {
                            ...price,
                            symbol: token?.symbol || ``,
                            amountAfterDiscount: amountAfterDiscount,
                            amountAfterDiscountForDisplay: `${toCoin(
                                formatUnitsWithPrecision(
                                    BigInt(amountAfterDiscount),
                                    token?.decimals
                                )
                            )} ${token?.symbol}`,
                        };
                    }) || [];

                return {
                    ...item,
                    isVariablePricing,
                    isOneTimePayment,
                    hasInitialOffset,
                    initialOffsetForDisplay,
                    frequencyForDisplay,
                    amountForDisplay,
                    dueDate,
                    couponDetail,
                    hasCoupon,
                    amountAfterDiscount,
                    amountAfterDiscountForDisplay,
                    amount: itemAmount,
                    prices: itemPrices,
                };
            }) || []
        );
    }, [items, couponsWithDetails, tokens, billDate]);

    return checkoutItems;
};
