import { ItemFrequency } from "types/common";
import { regex } from "utils/regex";

export enum TimeFrequency {
    MINUTE = "minute",
    HOUR = "hour",
    DAY = "day",
    WEEK = "week",
    MONTH = "month",
    YEAR = "year",
}

interface AddTimeParams {
    addend: number;
    startOfType?: boolean;
    from?: number;
    type?: TimeFrequency;
}

// ! Note, a less robust version of this exists in `timedate.js` - those should be converted to this version and that one removed
export const addTimePeriodToDate = ({
    addend,
    startOfType = false,
    from = Date.now() / 1000,
    type = TimeFrequency.DAY,
}: AddTimeParams) => {
    const dateFrom = new Date(from * 1000);

    const resetTime: () => void = (
        {
            minute: () => dateFrom.setSeconds(0),
            hour: () => dateFrom.setMinutes(0, 0),
            day: () => dateFrom.setHours(0, 0, 0),
            week: () => dateFrom.setHours(0, 0, 0),
            month: () => {
                dateFrom.setDate(1);
                dateFrom.setHours(0, 0, 0);
            },
            year: () => {
                dateFrom.setMonth(0, 1);
                dateFrom.setHours(0, 0, 0);
            },
        } as { [K in TimeFrequency]: () => void }
    )[type as TimeFrequency];

    const addTime: () => number = (
        {
            minute: () => dateFrom.setMinutes(dateFrom.getMinutes() + addend),
            hour: () => dateFrom.setHours(dateFrom.getHours() + addend),
            day: () => dateFrom.setDate(dateFrom.getDate() + addend),
            week: () => dateFrom.setDate(dateFrom.getDate() + 7 * addend),
            month: () => {
                const originalDay = dateFrom.getDate();
                dateFrom.setMonth(dateFrom.getMonth() + addend);
                if (originalDay !== dateFrom.getDate()) {
                    // Adjust to the last day of the previous month
                    dateFrom.setDate(0);
                }
                return dateFrom.getTime();
            },
            year: () => dateFrom.setFullYear(dateFrom.getFullYear() + addend),
        } as { [K in TimeFrequency]: () => number }
    )[type as TimeFrequency];

    if (startOfType && resetTime) resetTime();
    return new Date(addTime ? addTime() : dateFrom).valueOf();
};

// Provide a string date, and get the start of day (midnight) in local time, instead of UTC
interface GetLocalStartOfDayFromStringOptions {
    unix?: boolean;
}
export const getLocalStartOfDayFromString = (
    dateString: string,
    { unix = false }: GetLocalStartOfDayFromStringOptions = {}
): number => {
    if (!regex.dateTime.test(dateString)) {
        throw new Error("Invalid date format");
    }

    // Create a Date object for the given date (in local time)
    const localDate = new Date(dateString);

    // If Date() is given a string without a time, it will assume midnight UTC
    // If the string includes the time, it will assume the time is local
    if (!dateString.includes("T")) {
        localDate.setMinutes(
            localDate.getMinutes() + localDate.getTimezoneOffset()
        );
    }

    // Set to midnight
    localDate.setHours(0, 0, 0, 0);

    // Adjust the time by the timezone offset to get the UNIX timestamp
    return unix ? Math.floor(localDate.getTime() / 1000) : localDate.getTime();
};

export const getDateInSeconds = (date: number = Date.now()) => {
    return Math.floor(date / 1000);
};

export const getReadableDate = (
    seconds: number,
    locale: string = navigator.language
): string => {
    const date = new Date(seconds * 1000);
    return date.toLocaleDateString(locale, {
        year: "numeric",
        month: "short",
        day: "numeric",
    });
};

export const numberToWords = (num: number): string => {
    const words = [
        "zero",
        "one",
        "two",
        "three",
        "four",
        "five",
        "six",
        "seven",
        "eight",
        "nine",
        "ten",
    ];
    return num <= 10 ? words[num] : num.toString();
};

export const describeFrequency = (
    frequency: ItemFrequency | null | undefined
): string => {
    if (!frequency || frequency.value === 0 || frequency.type === "") return ``;

    const { type, value } = frequency;
    if (value === 1) return `per ${type}`;

    const valueInWords = numberToWords(value);
    return `every ${valueInWords} ${type}s`;
};
