import { useCallback, useState } from "react";
import { useAuthQuery } from "hooks/useAuthQuery";
import { HeadersObject } from "api/rest";
import { useUser } from "context/User";
import { ExtendedPagingParams } from "./useTableData";

interface TableQueryConfig<TResponse, THeadings, TFilters, TType = string> {
    queryKey: string;
    apiQueryFn: (
        entityId: string,
        request: ExtendedPagingParams<TFilters>,
        headers: HeadersObject
    ) => Promise<TResponse>;
    headings: RowHeading<THeadings>[];
    initialFilters?: TFilters;
    initialSort?: SortBy;
    initialPagination?: Pagination;
    id?: string;
    type?: TType;
}

export const useTableQuery = <TResponse, THeadings, TFilters, TType = string>({
    queryKey,
    apiQueryFn,
    headings,
    initialFilters = {} as TFilters,
    initialSort = { column: 0, isAscending: false },
    initialPagination = { perPage: 50, page: 1 },
    id,
    type,
}: TableQueryConfig<TResponse, THeadings, TFilters, TType>) => {
    const { getEntityId, getSessionToken } = useUser();
    const [filters, setFilters] = useState<TFilters>(initialFilters);
    const [sort, setSort] = useState<SortBy>(initialSort);
    const [page, setPage] = useState(initialPagination.page);

    const limit = initialPagination.perPage;
    const sortBy =
        sort?.column !== undefined
            ? headings[sort.column].field.toString()
            : undefined;
    const sortDir = sort?.isAscending ? "asc" : "desc";

    const query = useAuthQuery({
        queryKey: [
            queryKey,
            { page, limit, sortBy, sortDir, id, type, ...filters },
        ],
        queryFn: () =>
            apiQueryFn(
                getEntityId(),
                {
                    page: page - 1,
                    limit,
                    sortBy,
                    sortDir,
                    id,
                    type,
                    ...filters,
                },
                { Authorization: getSessionToken() }
            ),
    });

    const handlers = {
        handleFilterChange: useCallback((filters: TFilters) => {
            setFilters({ ...filters });
            setPage(1);
        }, []),

        handleSortChange: useCallback((_: unknown, direction?: SortBy) => {
            if (!direction) return;
            setSort({ ...direction });
            setPage(1);
        }, []),

        handlePageChange: useCallback((newPage: number) => {
            setPage(newPage);
        }, []),
    };

    return {
        query,
        pagination: { page, perPage: initialPagination.perPage },
        sort,
        filters,
        handlers,
    };
};

export default useTableQuery;
