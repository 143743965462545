import * as S from "./style";
import { useEffect, useMemo } from "react";
import { Navigate, useParams } from "react-router-dom";
import { CustomerAuthorization } from "customer/types";
import { toCoin, toDollar } from "utils/financial";
import { isStableCoin } from "utils/tokens";
import { getReadableDate } from "utils/dates";
import {
    getSubscriptionAmountAndFrequency,
    hasSufficientAmountForNextPayment,
} from "customer/utils/subscriptions";
import { Density } from "types/common-enums";
import { Spacing } from "theme/spacing";
import useAuthorizations from "customer/hooks/useAuthorizations";
import useTokenAllowance from "customer/hooks/useTokenAllowance";
import useTokenBalance from "customer/hooks/useTokenBalance";
import Title from "components/Title";
import Paragraph from "components/Paragraph";
import DescriptionList from "components/DescriptionList";
import DynamicAddressDisplay from "components/DynamicAddressDisplay";
import TokenAllowance from "components/TokenAllowance";
import { useNotificationQueue } from "context/NotificationQueue";
import { NotificationType } from "components/Notification";
import Button, { ButtonVariants } from "components/Button";
import Anchor from "components/Anchor";
import { useCustomer } from "customer/context/Customer";
import SectionAndTitle from "customer/components/SectionAndTitle";
import Tooltip from "components/Tooltip";
import { itemSpacing } from "customer/App/style";
import TooltipOnOverflow from "components/TooltipOnOverflow";
import Badge from "components/Badge";

const Subscription = () => {
    const { addNotification, removeNotification } = useNotificationQueue();
    const { agreementId } = useParams();

    const {
        getAuthorization,
        cancelAuthorization,
        getCustomerDataRefetch,
        isLoading,
    } = useAuthorizations();

    const subscription = useMemo(
        () => (agreementId ? getAuthorization(agreementId) : undefined),
        [agreementId, getAuthorization]
    );

    const { balanceCoin, balanceUsd, isLoadingBalance } =
        useTokenBalance(subscription);
    const { allowanceUsd, allowanceCoin } = useTokenAllowance(subscription);

    const { setIsLoading } = useCustomer();
    useEffect(() => {
        setIsLoading(isLoading);
    }, [setIsLoading, isLoading]);

    // Balance checks
    // [ ] If the subscription is priced in token, this falls apart (we won't need to show USD conversion, and sufficient balance has to compare to balance, not balanceUsd)
    const willShowUsd =
        balanceUsd && subscription && !isStableCoin(subscription.token);

    const hasSufficientBalance = hasSufficientAmountForNextPayment({
        nextPayment: subscription?.nextPayment,
        amountUsd: balanceUsd,
        amountCoin: balanceCoin,
    });

    const hasSufficientAllowance = hasSufficientAmountForNextPayment({
        nextPayment: subscription?.nextPayment,
        amountUsd: allowanceUsd,
        amountCoin: allowanceCoin,
    });

    const handleCancelSubscription = async (
        subscription: CustomerAuthorization
    ) => {
        try {
            addNotification({
                msg: `Cancelling subscription for ${subscription.itemName}`,
                type: NotificationType.WORKING,
                customId: subscription.id,
            });
            await cancelAuthorization(subscription);
            await getCustomerDataRefetch();

            // Because we don't show cancelled authorizations, navigate back to the dashboard
            return <Navigate to="/" />;
        } catch (error) {
            addNotification({
                msg: (
                    <>
                        Unable to cancel this subscription
                        <br />
                        Try again, or{" "}
                        <Anchor
                            href={`mailto:${
                                import.meta.env.VITE_EMAIL_HELP
                            }?subject=Problem%20canceling%20authorization%20${
                                subscription.id
                            }`}
                        >
                            contact support for assistance
                        </Anchor>
                        .
                    </>
                ),
                type: NotificationType.ERROR,
                expires: false,
            });
        } finally {
            removeNotification(subscription.id);
        }
    };

    const paymentMethods = useMemo(() => {
        if (
            !subscription ||
            !balanceCoin ||
            balanceUsd === null ||
            allowanceUsd === null
        ) {
            return [];
        }

        return [
            {
                term: `Wallet`,
                definition: (
                    <DynamicAddressDisplay
                        address={subscription.wallet}
                        networkId={subscription.networkHex}
                        inheritColor={false}
                        placement="top"
                        shorten
                    />
                ),
                style: S.Row,
            },
            {
                term: `Currency`,
                definition: `${subscription.tokenSymbol} on ${subscription.networkName}`,
                style: S.Row,
            },
            {
                term: `Balance`,
                definition:
                    isLoadingBalance || !balanceCoin ? (
                        <S.Loading desaturate />
                    ) : (
                        <>
                            <Tooltip
                                title={
                                    balanceCoin !== toCoin(balanceCoin)
                                        ? balanceCoin
                                        : undefined
                                }
                            >
                                <data>{toCoin(balanceCoin)}</data>
                            </Tooltip>
                            {` `}
                            {subscription.tokenSymbol}
                            {willShowUsd && ` (${toDollar(balanceUsd)})`}

                            {subscription?.nextPayment &&
                                balanceUsd !== null &&
                                !hasSufficientBalance &&
                                subscription.active && (
                                    <S.LowWarning
                                        variant="red"
                                        density={Density.Default}
                                    >
                                        Increase
                                    </S.LowWarning>
                                )}
                        </>
                    ),
                style: S.Row,
            },
            {
                term: `Authorization`,
                definition: subscription.token ? (
                    <>
                        <TokenAllowance
                            token={subscription.token}
                            contract={subscription.contract}
                            wallet={subscription.wallet}
                            symbolPosition="after"
                            spaceBetween={Spacing.xs}
                            usdRate={
                                !isStableCoin(subscription.token)
                                    ? subscription.token.exchange.rate
                                    : undefined
                            }
                        />
                        {subscription?.nextPayment &&
                            allowanceUsd !== null &&
                            !hasSufficientAllowance &&
                            subscription.active && (
                                <S.LowWarning
                                    variant="red"
                                    density={Density.Default}
                                >
                                    Increase
                                </S.LowWarning>
                            )}
                    </>
                ) : (
                    <></>
                ),
                style: S.Row,
            },
        ];
    }, [
        subscription,
        isLoadingBalance,
        balanceCoin,
        balanceUsd,
        allowanceUsd,
        hasSufficientBalance,
        hasSufficientAllowance,
        willShowUsd,
    ]);

    const billingInformation = [
        { term: `Email`, definition: subscription?.email, style: S.Row },
    ];

    if (!subscription) {
        // [ ] Register error to dashboard
        // addNotification({
        //     msg: `Authorization not found`,
        //     type: NotificationType.ERROR,
        // });
        return <Navigate to="/" />;
    }

    return (
        <article>
            <SectionAndTitle
                title={`${
                    subscription.active ? `Current` : `Canceled`
                } Subscription`}
            >
                <S.SubscriptionHeader>
                    <div>
                        <Title level="h2" size="h3" bold={false}>
                            {subscription.itemName}
                        </Title>
                        <S.Price
                            level="h4"
                            size="h1"
                            bold={false}
                            spacing={[Spacing.xxs]}
                        >
                            {getSubscriptionAmountAndFrequency(
                                subscription.amount,
                                subscription.frequency
                            )}
                            {subscription.isPastDue && (
                                <Badge density={Density.Default} variant="red">
                                    Past due
                                </Badge>
                            )}
                        </S.Price>
                        <Paragraph>
                            {subscription.statusOfNextPayment}
                        </Paragraph>
                    </div>
                    {subscription.active && (
                        <Button
                            variant={ButtonVariants.PrimaryOutlined}
                            onClick={() =>
                                handleCancelSubscription(subscription)
                            }
                        >
                            Cancel subscription
                        </Button>
                    )}
                </S.SubscriptionHeader>
            </SectionAndTitle>
            <SectionAndTitle title="Payment Method">
                <DescriptionList
                    items={paymentMethods}
                    spacing={[itemSpacing]}
                />
            </SectionAndTitle>
            <SectionAndTitle title="Billing Information">
                <DescriptionList
                    items={billingInformation}
                    spacing={[itemSpacing]}
                />
            </SectionAndTitle>
            <SectionAndTitle title="Payment History">
                {subscription.transactions.length ? (
                    <S.HistoryTable>
                        {subscription.transactions.map((transaction) => (
                            <S.ListRow key={transaction.id}>
                                <data>
                                    {getReadableDate(transaction.billDate)}
                                </data>
                                <data>{transaction.amount}</data>
                                <data>{transaction.txHash}</data>
                                <TooltipOnOverflow as="data">
                                    {transaction.itemName}
                                </TooltipOnOverflow>
                            </S.ListRow>
                        ))}
                    </S.HistoryTable>
                ) : (
                    <Paragraph spacing={[Spacing.sm]}>
                        No transaction history
                    </Paragraph>
                )}
            </SectionAndTitle>
        </article>
    );
};

export default Subscription;
