import * as S from "./style";
import { Outlet } from "react-router-dom";
import { TransactionDataLoader } from "company/types";
import Question from "components/icons/Question";
import Indicator from "components/Indicator";
import Submenu, { SubmenuItem } from "components/Submenu";
import { getPastAndFutureDateRanges } from "utils/filters";
import { useGetCompanyTransfers } from "company/hooks/useGetCompanyTransfers";
import { TransactionType } from "company/routes/Transactions/hooks/useTransactionTable";

const { past, future } = getPastAndFutureDateRanges();

export const transactionDataLoader = (): TransactionDataLoader => {
    return {
        sort: {
            column: 0,
            isAscending: true,
        },
        pagination: {
            perPage: 50,
            page: 1,
        },
        past,
        future,
    };
};

const Transactions = () => {
    const { totalResults } = useGetCompanyTransfers({
        type: TransactionType.DUE,
        limit: 100,
    });

    const submenu: SubmenuItem[] = [
        {
            to: `confirmed`,
            label: `Confirmed`,
        },
        {
            to: `upcoming`,
            label: `Upcoming`,
        },
        {
            to: `due`,
            label: (
                <>
                    Due
                    {totalResults ? (
                        <>
                            {" "}
                            <Indicator>
                                {totalResults > 99 ? `99+` : totalResults}
                            </Indicator>
                        </>
                    ) : (
                        ``
                    )}
                </>
            ),
        },
        {
            to: `uncollectible`,
            label: `Uncollectible`,
        },
        {
            to: `canceled`,
            label: `Canceled`,
        },
    ];

    return (
        <>
            <S.TypeRow>
                <Submenu data={submenu} name="Transaction types" />
                <S.TxTypeTips
                    title={
                        <>
                            Transaction Types:
                            <ul>
                                <li>
                                    <strong>Confirmed</strong> - Any transaction
                                    with 35 confirmations on-chain.
                                </li>
                                <li>
                                    <strong>Upcoming</strong> - Any transaction
                                    due in the future.
                                </li>
                                <li>
                                    <strong>Uncollectible</strong> - Any
                                    unprocessed transaction with a due date that
                                    has passed and all attempts to automatically
                                    process have ended.
                                </li>
                                <li>
                                    <strong>Due</strong> - Any unprocessed
                                    transaction with a due date that has passed.
                                </li>
                                <li>
                                    <strong>Canceled</strong> - Payment requests
                                    that have been canceled.
                                </li>
                            </ul>
                        </>
                    }
                    placement="bottom"
                    noWrap
                >
                    <S.QIcon style={{ lineHeight: `1` }}>
                        <Question height="1rem" width="1rem" />
                    </S.QIcon>
                </S.TxTypeTips>
            </S.TypeRow>

            <Outlet />
        </>
    );
};

export default Transactions;
