import * as S from "invoice/components/InvoicesTable/style";
import { formatUnits } from "ethers";
import { getInvoicePayments } from "api";
import { getDateTimeFromSeconds } from "utils/datetime";
import { toCoin, toDollar } from "utils/financial";
import { toNetworkHex } from "utils/addresses";
import {
    InvoiceNetworkConfig,
    InvoiceStatus,
    PaginatedPaymentViewResponse,
} from "api/types/invoice";
import {
    InvoiceDataLoader,
    InvoicePaymentHeadings,
    InvoicePaymenTableColumns,
    GetInvoicePaymentsHttpRequest,
} from "invoice/types";
import { useTableData, TableFormattingData } from "hooks/useTableData";
import useGetInvoiceConfig from "invoice/hooks/useGetInvoiceConfig";
import DynamicAddressDisplay from "components/DynamicAddressDisplay";
import { BlockExplorerEntityType } from "utils/urls";
import Checkmark from "components/icons/Checkmark";
import colors from "theme/colors";
import Warning from "components/icons/Warning";
import Question from "components/icons/Question";
import Info from "components/icons/Info";
import Tooltip from "components/Tooltip";

const headings: RowHeading<InvoicePaymentHeadings>[] = [
    {
        label: "Paid",
        field: InvoicePaymenTableColumns.datePaid,
        sortable: true,
    },
    {
        label: "Invoice",
        field: InvoicePaymenTableColumns.invoiceId,
        sortable: true,
    },
    {
        label: "From",
        field: InvoicePaymenTableColumns.from,
        sortable: true,
    },
    {
        label: "Amount",
        field: InvoicePaymenTableColumns.amount,
        sortable: true,
    },
    {
        label: "Network",
        field: InvoicePaymenTableColumns.network,
        sortable: true,
    },
    {
        label: "Status",
        field: InvoicePaymenTableColumns.status,
        sortable: true,
    },
];

export interface FormattingData {
    networks: InvoiceNetworkConfig[];
}

const formatInvoiceData = ({
    response,
    formattingData: { networks },
}: TableFormattingData<
    PaginatedPaymentViewResponse | undefined,
    FormattingData
>) => {
    const { invoices = [], totalResults = 0 } = response || {};

    const records: Tuple[] = invoices.map((invoice) => {
        const {
            date: atDate,
            time: atTime,
            zone: atZone,
        } = getDateTimeFromSeconds(invoice.datePaid ?? 0, true);
        const dateFormatted = invoice.datePaid ? (
            <>
                {atDate}{" "}
                <S.Subpoint>
                    {atTime} {atZone}
                </S.Subpoint>
            </>
        ) : (
            `-`
        );
        const network = networks.find((n) => n.id === invoice.networkId);
        const token = network?.tokens.find(
            (t) => t.address === invoice.tokenAddress
        );
        const tokenAmount = toCoin(
            formatUnits(invoice.tokenAmount || 0, token?.decimals || 0)
        );

        const statusIcon =
            invoice.status === InvoiceStatus.Successful ? (
                <Checkmark
                    fill={colors.success}
                    height="1rem"
                    width="1rem"
                    style={{ verticalAlign: `middle` }}
                />
            ) : invoice.status === InvoiceStatus.Failed ? (
                <Warning
                    fill={colors.error}
                    height="1rem"
                    width="1rem"
                    style={{ verticalAlign: `middle` }}
                />
            ) : invoice.status === InvoiceStatus.Pending ? (
                <Info
                    fill={colors.primary}
                    height="1rem"
                    width="1rem"
                    style={{ verticalAlign: `middle` }}
                />
            ) : invoice.status === InvoiceStatus.Irregular ? (
                <Question
                    fill={colors.warning}
                    height="1rem"
                    width="1rem"
                    style={{ verticalAlign: `middle` }}
                />
            ) : null;

        const statusText =
            invoice.status === InvoiceStatus.Successful
                ? `Payment confirmed`
                : invoice.status === InvoiceStatus.Failed
                  ? `Payment failed`
                  : invoice.status === InvoiceStatus.Pending
                    ? `Awaiting confirmation`
                    : invoice.status === InvoiceStatus.Irregular
                      ? `Unable to confirm this payment`
                      : null;

        const status = statusIcon && statusText && (
            <Tooltip title={statusText} placement="left">
                <span>{statusIcon}</span>
            </Tooltip>
        );

        return {
            id: invoice.id,
            values: [
                {
                    label: dateFormatted,
                    value: invoice.datePaid || 0,
                    text: invoice.datePaid
                        ? `${atDate} ${atTime} ${atZone}`
                        : `-`,
                },
                {
                    label: invoice.invoiceId,
                    value: invoice.invoiceId,
                    text: invoice.invoiceId,
                    style: { verticalAlign: `top` },
                },
                {
                    label: (
                        <>
                            {invoice.email}
                            <br />
                            <S.Subpoint>
                                <DynamicAddressDisplay
                                    address={invoice.fromWallet}
                                    networkId={toNetworkHex(invoice.networkId)}
                                    shorten
                                />
                            </S.Subpoint>
                        </>
                    ),
                    value: `${invoice.email}, ${invoice.fromWallet}`,
                    text: `${invoice.email}, ${invoice.fromWallet}`,
                },
                {
                    label: (
                        <>
                            {toDollar(invoice.usdAmount)}
                            <S.Subpoint>
                                {tokenAmount} {invoice.tokenSymbol}
                            </S.Subpoint>
                        </>
                    ),
                    value: `${toDollar(invoice.usdAmount)}, ${tokenAmount}`,
                    text: `${toDollar(invoice.usdAmount)}, ${tokenAmount} ${
                        invoice.tokenSymbol
                    }`,
                },
                {
                    label: network?.name || `-`,
                    value: `${network?.name} (${invoice.networkId})`,
                    text: network?.name || `-`,
                    style: { verticalAlign: `top` },
                },
                {
                    label: (
                        <S.Status>
                            {status}{" "}
                            <DynamicAddressDisplay
                                address={invoice.txHash}
                                networkId={toNetworkHex(invoice.networkId)}
                                type={BlockExplorerEntityType.Transaction}
                                shorten
                            />
                        </S.Status>
                    ),
                    value: `${invoice.status}: ${invoice.txHash}`,
                    text: `${invoice.status}: ${invoice.txHash}`,
                    style: { verticalAlign: `top` },
                },
            ],
        };
    });

    return { response, totalResults, records };
};

export const invoiceDataLoader = (): InvoiceDataLoader => {
    return {
        sort: {
            column: 0,
            isAscending: true,
        },
        pagination: {
            perPage: 50,
            page: 1,
        },
    };
};

interface UseInvoiceTableProps {
    initialFilters?: GetInvoicePaymentsHttpRequest;
    initialSort?: SortBy;
    initialPagination?: Pagination;
}

export const useInvoiceTable = (config: UseInvoiceTableProps) => {
    const {
        data: { entity, networks },
        isLoading: configLoading,
    } = useGetInvoiceConfig();

    const tableData = useTableData<
        PaginatedPaymentViewResponse | undefined,
        GetInvoicePaymentsHttpRequest,
        InvoicePaymentHeadings,
        FormattingData
    >({
        queryKey: "invoicePayments",
        apiQueryFn: getInvoicePayments,
        headings,
        formatFn: formatInvoiceData,
        formattingData: { networks },
        ...config,
    });

    return {
        ...tableData,
        isLoading: configLoading || tableData.isLoading,
        entity,
        networks,
    };
};

export default useInvoiceTable;
