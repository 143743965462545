import {
    SortableCompanyTransactionColumns,
    TransactionTableRow,
    TransactionValues,
} from "company/types";
import { useCallback, useEffect, useState } from "react";
import { useTransactionFormat } from "./useTransactionFormat";
import { rowsToTableRecords } from "utils/tables";
import { FiltersRef } from "company/routes/Transactions/components/Filters";
import { getDateInSeconds } from "utils/dates";
import { useGetCompanyTransfers } from "company/hooks/useGetCompanyTransfers";
import { useGetCompanyConfig } from "company/hooks/useGetCompanyConfig";
import { useGetNetworks } from "hooks/useGetNetworks";
import { useGetTokensMetadata } from "hooks/useGetTokensMetadata";

export enum TransactionType {
    CONFIRMED = "confirmed",
    UPCOMING = "upcoming",
    UNCOLLECTIBLE = "uncollectible",
    DUE = "due",
    CANCELED = "canceled",
}

export interface TransactionFilters {
    date?: number;
    search?: string;
    entities?: string;
    networks?: string;
}

interface TransactionTableProps {
    columns: TransactionValues[];
    type?: TransactionType;
    initialFilters?: Record<string, any>;
    initialSort?: SortBy;
    pagination?: Pagination;
    filtersRef?: React.MutableRefObject<FiltersRef | null>;
}

export const useTransactionTable = ({
    columns,
    type,
    initialFilters = {},
    initialSort = { column: 0, isAscending: false },
    pagination = { perPage: 50, page: 1 },
    filtersRef,
}: TransactionTableProps) => {
    const [headings, setHeadings] = useState<RowHeading<TransactionTableRow>[]>(
        []
    );
    const [records, setRecords] = useState<Tuple[]>([]);

    const [filters, setFilters] = useState<TransactionFilters>(initialFilters);
    const [sort, setSort] = useState<SortBy>(initialSort);
    const [page, setPage] = useState(pagination.page);

    const {
        config: { entities },
        getCompanyConfigIsLoading,
        getCompanyConfigIsError,
        getCompanyConfigIsFetching,
    } = useGetCompanyConfig();

    const { tokens, getTokensMetadataIsLoading, getTokensMetadataIsError } =
        useGetTokensMetadata();

    const {
        networks,
        getNetworksIsLoading,
        getNetworksIsError,
        getNetworksIsFetching,
    } = useGetNetworks();

    const {
        getCompanyTransfersIsLoading,
        getCompanyTransfersIsError,
        getCompanyTransfersIsFetching,
        getCompanyTransfersRefetch,
        getCompanyTransfersDataUpdatedAt,
        transactions,
        totalResults,
    } = useGetCompanyTransfers({
        type,
        filters,
        sortBy:
            sort?.column !== undefined
                ? (TransactionValues[
                      columns[sort.column]
                  ] as SortableCompanyTransactionColumns)
                : undefined,
        sortDir: sort?.isAscending ? "asc" : "desc",
        page: page - 1,
        limit: pagination.perPage,
    });

    const { getTransactionsTableHeadings, getFormatTransactions } =
        useTransactionFormat({ entities, tokens, networks });

    const handleFilterChange = useCallback(() => {
        if (!filtersRef?.current) return;

        const {
            dateRange,
            search,
            selectedEntitiesNames,
            selectedNetworksNames,
        } = filtersRef.current;

        setFilters({
            date: getDateInSeconds(dateRange),
            search,
            entities: selectedEntitiesNames.join(`,`),
            networks: selectedNetworksNames.join(`,`),
        });
        setPage(pagination.page);
    }, [filtersRef, pagination.page]);

    const handleSortChange = useCallback(
        (_: any, direction?: SortBy) => {
            if (!direction) return;

            setSort({ ...direction });
            setPage(pagination.page);
        },
        [pagination.page]
    );

    const handlePageChange = useCallback((page: number) => {
        setPage(page);
    }, []);

    const isLoading =
        getTokensMetadataIsLoading ||
        getCompanyConfigIsLoading ||
        getNetworksIsLoading ||
        getCompanyTransfersIsLoading;

    useEffect(() => {
        if (isLoading) return;

        const colHeadings = getTransactionsTableHeadings(columns);
        setHeadings(colHeadings);

        const txRows = getFormatTransactions(transactions);
        setRecords(rowsToTableRecords(txRows, colHeadings));
    }, [
        getTransactionsTableHeadings,
        getFormatTransactions,
        columns,
        transactions,
        isLoading,
    ]);

    const isError =
        getTokensMetadataIsError ||
        getCompanyConfigIsError ||
        getNetworksIsError ||
        getCompanyTransfersIsError;

    const isFetching =
        getCompanyTransfersIsFetching ||
        getCompanyConfigIsFetching ||
        getNetworksIsFetching;

    return {
        totalResults,
        headings,
        records,
        page,
        sort,
        handleFilterChange,
        handleSortChange,
        handlePageChange,
        transactionsIsLoading: isLoading,
        transactionsIsError: isError,
        transactionsIsFetching: isFetching,
        transactionsRefetch: getCompanyTransfersRefetch,
        transactionsDataUpdatedAt: getCompanyTransfersDataUpdatedAt,
    };
};
